import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

const MediaArticle = () => (
  <Layout>
    <Seo
      title="Bangalore Mirror - July 2014"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <section className="awards_article p_x">
      <Container>
        <Row className="no-gutters">
          <Col>
            <StaticImage
              src="../../images/awards/articles/bangalore-mirror-july-2014.jpg"
              alt="Bangalore Mirror - July 2014"
            />{" "}
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default MediaArticle
